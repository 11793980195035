import React, { useEffect, useState } from 'react';
import { MapContainer, ImageOverlay, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import imageUrl from 'assets/img/navbar/mineMap.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Map = () => {
  const imageBounds = [[0, 0], [500, 500]]; // Adjust to your image dimensions
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const ws = new WebSocket('wss://api-dev.subsecure.in/ws/locations/');

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.locations) {
        setLocations(data.locations);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <MapContainer
      center={[500, 500]} // Center the map on the image
      zoom={0.001} // Adjust zoom level as needed
      minZoom={-3} // Allow zooming out further
      maxBounds={imageBounds} // Prevent panning outside the image
      style={{ height: '70vh', width: '100%' }}
      crs={L.CRS.Simple} // Use simple coordinate reference system
    >
      <ImageOverlay url={imageUrl} bounds={imageBounds} />
      {locations.map((location, index) => (
        <Marker key={index} position={[location.y || 0, location.x || 0]}>
          <Tooltip>
            <div>
              <p><strong>ID:</strong> {location.id}</p>
              <p><strong>Description:</strong> {location.description}</p>
              <p><strong>Temperature:</strong> {location.temperature}°C</p>
              <p><strong>Humidity:</strong> {location.humidity}%</p>
              <p><strong>Workers:</strong> {location.number_of_workers}</p>
              <p><strong>PM10:</strong> {location.pm10_level}</p>
              <p><strong>PM2.5:</strong> {location.pm25_level}</p>
              <p><strong>Emergency:</strong> {location.emergency_bit ? 'Yes' : 'No'}</p>
            </div>
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
