// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";

// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Sentinal Mines Jharia Fields
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Inagurated on 15 April 1977, Jharia Coal Mines produces 40 million Bituminous Coal annually.
      </Text>
      <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='Location'
          value='Jharia, Jharkhand'
        />

        <Information
          boxShadow={cardShadow}
          title='Manager'
          value='Hushraj Singh'
        />
        <Information
          boxShadow={cardShadow}
          title='Installation Date'
          value='12 November 2024'
        />
        <Information
          boxShadow={cardShadow}
          title='Next Service'
          value='12 November 2025'
        />
        <Information
          boxShadow={cardShadow}
          title='Organization'
          value='Sentinal Mines Pvt. Ltd.'
        />
        <Information
          boxShadow={cardShadow}
          title='Emergency Contact'
          value='7717644689'
        />
      </SimpleGrid>
    </Card>
  );
}
