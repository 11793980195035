import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { MdSecurity } from "react-icons/md";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;

  const textColor = useColorModeValue("gray.700", "white");

  // State to hold the metrics
  const [metrics, setMetrics] = useState({
    totalNodes: 0,
    totalOnlineNodes: 0,
    totalWorkers: 0,
    totalWorkersAvailable: 0,
    workersInsideMine: 0,
    sosAlarms: 0,
    unsafeLocation: 0,
    uptime: 0,
  });

  useEffect(() => {
    // Establish WebSocket connections
    const socketLocations = new WebSocket('wss://api-dev.subsecure.in/ws/locations/');
    const socketWorkers = new WebSocket('wss://api-dev.subsecure.in/ws/workers/');

    socketLocations.onopen = () => {
      console.log('WebSocket connection established for locations');
    };

    socketWorkers.onopen = () => {
      console.log('WebSocket connection established for workers');
    };

    // Handling incoming messages from the locations WebSocket
    socketLocations.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Calculate the relevant metrics based on location data (example logic)
      if (data.locations) {
        const totalNodes = data.locations.length; // Assuming locations data contains nodes
        const totalOnlineNodes = data.locations.filter(loc => loc.available === true).length;
        const unsafeLocation = data.locations.filter(loc => loc.emergency_bit === true).length;

        setMetrics(prevMetrics => ({
          ...prevMetrics,
          totalNodes,
          totalOnlineNodes,
          unsafeLocation,
        }));
      }
    };

    // Handling incoming messages from the workers WebSocket
    socketWorkers.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Calculate the relevant metrics based on worker data (example logic)
      if (data.workers) {
        const totalWorkers = data.workers.length; // Assuming workers data contains the list of workers
        const totalWorkersAvailable = data.workers.filter(worker => worker.available === true).length;
        const workersInsideMine = data.workers.filter(worker => worker.location_id !== null).length;
        const sosAlarms = data.workers.filter(worker => worker.sos === true).length;


        setMetrics(prevMetrics => ({
          ...prevMetrics,
          totalWorkers,
          totalWorkersAvailable,
          workersInsideMine,
          sosAlarms,          
        }));
      }
    };

    // Clean up WebSocket connections on unmount
    return () => {
      socketLocations.close();
      socketWorkers.close();
    };
  }, []);

  // Mock uptime and safety drills data (you can replace this with real data logic)
  const uptime = "10 hours"; // Example uptime (hours)

  // Updating the metrics state with static data
  useEffect(() => {
    setMetrics(prevMetrics => ({
      ...prevMetrics,
      uptime,
    }));
  }, [uptime]);

  // Function to capitalize the first letter of each word
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Card {...rest}>
      <Flex direction="column" p="20px">
        <Text color={textColor} fontSize="lg" fontWeight="bold" mb="6px">
          Mining Operation Metrics
        </Text>
        <Box>
          {/* Map over the updated metrics and display them */}
          {Object.entries(metrics).map(([label, value], index) => (
            <Flex key={index} justify="space-between" mb="4px" fontSize={{ sm: '14px' }}
              minW={{ sm: '150px', md: '200px', lg: 'auto' }}>
              <Text color={textColor} fontSize={{ sm: '14px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}>
                {capitalizeWords(label.replace(/([A-Z])/g, ' $1'))} {/* Format label */}
              </Text>
              <Text color={textColor} fontSize={{ sm: '14px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }} fontWeight="semibold">
                {value}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Card>
  );
}
