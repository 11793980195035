import React from 'react';
import { MapContainer, ImageOverlay, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import imageUrl from 'assets/img/navbar/mineMap.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Map = () => {
   // Replace with your image path
  const imageBounds = [[0, 0], [1000, 1000]]; // Replace with the dimensions of your image

  const markers = [
    { position: [50, 340], text: 'id xx02', data: { sno: 2, uniqueId: 'Node-002', uptime: '22 hours', workers: 83 } }, // Adjust positions as needed
    { position: [700, 700], text: ' id xx03', data: { sno: 3, uniqueId: 'Node-003', uptime: '20 hours', workers: 57 } },
    { position: [300, 100], text: 'id xx01', data: { sno: 1, uniqueId: 'Node-001', uptime: '24 hours', workers: 40 } },
    { position: [600, 900], text: 'id xx04', data: { sno: 4, uniqueId: 'Node-004', uptime: '18 hours', workers: 65 } }
  ];
  

  return (
    <MapContainer
      center={[500, 500]} // Center the map on the image
      zoom={1} // Adjust zoom level as needed
      minZoom={-3} // Allow zooming out further
      maxBounds={imageBounds} // Prevent panning outside the image
      style={{ height: '100vh', width: '100%' }}
      crs={L.CRS.Simple} // Use simple coordinate reference system
    >
      <ImageOverlay url={imageUrl} bounds={imageBounds} />
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position}>
          <Tooltip>
            <div>
              <p><strong>ID:</strong> {marker.data.uniqueId}</p>
              <p><strong>Uptime:</strong> {marker.data.uptime}</p>
              <p><strong>Workers:</strong> {marker.data.workers}</p>
            </div>
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
