import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
import { RiRouterFill } from "react-icons/ri";

import { FiAlertTriangle } from "react-icons/fi";
import MainDashboard from 'views/admin/default';
import MineGrid from 'views/admin/map';
import AddWorker from 'views/admin/add-workers';
import Attendence from 'views/admin/attendence';
import Alerts from 'views/admin/alerts';

import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import { FaMapMarked, FaUserCheck, FaUserPlus } from 'react-icons/fa';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Detailed Status',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: <DataTables />,
  },
  // {
  //   name: 'Profile',
  //   layout: '/admin',
  //   path: '/profile',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <Profile />,
  // },
  {
    name: 'Mine Grid',
    layout: '/admin',
    path: '/map',
    icon: <Icon as={FaMapMarked} width="20px" height="20px" color="inherit" />,
    component: <MineGrid />,
  },
  {
    name: 'Add Worker',
    layout: '/admin',
    path: '/add-worker',
    icon: <Icon as={FaUserPlus} width="20px" height="20px" color="inherit" />,
    component: <AddWorker />,
  },
  {
    name: 'Attendence',
    layout: '/admin',
    path: '/attendence',
    icon: <Icon as={FaUserCheck} width="20px" height="20px" color="inherit" />,
    component: <Attendence />,
  },
  // {
  //   name: 'Add Node',
  //   layout: '/admin',
  //   path: '/add-node',
  //   icon: <Icon as={RiRouterFill} width="20px" height="20px" color="inherit" />,
  //   component: <Attendence />,
  // },
  {
    name: 'Alerts',
    layout: '/admin',
    path: '/alerts',
    icon: <Icon as={ FiAlertTriangle} width="20px" height="20px" color="inherit" />,
    component: <Alerts />,
  },
  
  // {
  //   name: 'Sign In',
  //   layout: '/auth',
  //   path: '/sign-in',
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: <SignInCentered />,
  // }
  //  ,
  // {
  //   name: 'RTL Admin',
  //   layout: '/rtl',
  //   path: '/rtl-default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: <RTL />,
  // }, 
];

export default routes;
