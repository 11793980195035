import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import WorkersTable from "views/admin/add-workers/components/ComplexTable";
import { columnsDataComplex } from "views/admin/dataTables/variables/columnsData";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";

export default function Settings() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  // State for form inputs
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [aadhar, setAadhar] = useState("");

  // Submit function
  const submitForm = async () => {
    const payload = {
      id: `W${Math.floor(Math.random() * 1000).toString().padStart(3, "1")}`, // Randomly generate ID
      name: name,
      age: parseInt(age),
      aadhar: aadhar,
    };

    try {
      const response = await fetch("https://api-dev.subsecure.in/api/add-worker/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Worker added successfully!");
        // Optionally, reset form
        setName("");
        setAge("");
        setAadhar("");
      } else {
        console.error("Error:", response.statusText);
        alert("Failed to add worker.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Mohan Lal"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Age<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="number"
            placeholder="32"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Aadhar Number<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="124578965823"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={aadhar}
            onChange={(e) => setAadhar(e.target.value)}
          />

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={submitForm}
          >
            Submit
          </Button>
        </FormControl>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <WorkersTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
          />
        </SimpleGrid>
      </Box>
    </>
  );
}
