import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Image } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  // Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Image src={require("assets/img/navbar/subsecure.png")} h='40px' w='175px' my='32px' alt='SubSecure Logo' />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
